import {
  DatePickerElement,
  DateTimePickerElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { CropperModal } from '../../Common/ImageCropper';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import moment from 'moment';
import dayjs from 'dayjs';

type FormProps = {
  name: string;
  description: string;
  // startDate: Date;
  // endDate: Date;
  // startDate: string;
  // endDate: string;
  startDate: dayjs.Dayjs | null, // dayjs object or null
  endDate: dayjs.Dayjs | null,
  images: string[];
};
const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },

  deleteButton: {
    position: 'absolute' as 'absolute',
    top: '-8px', // Adjust the position slightly to make it fit better with the image
    right: '-8px',
    backgroundColor: '#FF5C5C', // Red background for delete action
    color: '#fff', // White icon color
    padding: '4px', // Adjust padding for smaller button
    borderRadius: '50%', // Ensures a circular shape
    cursor: 'pointer',
    border: 'none',
    fontSize: '12px', // Smaller size for extra small
    width: '20px', // Adjust width of the button
    height: '20px', // Adjust height of the button
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',  // Soft shadow to give it depth
    transition: 'background-color 0.3s ease, transform 0.2s', // Smooth transitions
  },
  deleteButtonHover: {
    backgroundColor: '#FF3B3B',  // Slightly darker red on hover
    transform: 'scale(1.1)', // Slightly enlarge the button on hover for interactivity
  },
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

function AddAdvertisement({ setOpen, setAdvertisements }) {
  const defaultValues: FormProps = {
    name: '',
    description: '',
    // startDate: moment().toDate(),
    // endDate: moment().add(1, 'day').toDate(),
    //   startDate: new Date(),
    // endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    // startDate: '',
    // endDate: '',
    startDate: dayjs(),
    // endDate: dayjs().add(1, "day"),
    endDate: dayjs(),

    images: [],
  };
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [src, setSrc] = useState<string | null>(null);
  const [preview, setPreview] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs());

  const methods = useForm<FormProps>({ defaultValues });
  const { watch, control } = methods;
  const watchedStartDate = watch("startDate"); // watching the startDate field



  const [error, setError] = useState<string>("");

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'minDate': {
        console.log("validation error: ", error);
        return toastMessage('End Date should be after or same as Start Date', 'error');
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [error]);



  // Handle file change (for new image upload)
  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files as FileList); // Get selected files
    const newFiles = files.map((file) => URL.createObjectURL(file)); // Create object URLs for previews

    // If multiple images are selected, update both the preview and imageFiles arrays
    setImageFiles((prevFiles) => [...prevFiles, ...files]); // Append the new files
    setPreview((prevPreview) => [...prevPreview, ...newFiles]); // Append new image previews

    // Open modal or show preview after file selection
    setModalOpen(true);
  };

  // Delete a specific image
  const deleteImage = (index: number) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove from image files state
    setPreview((prevPreview) => prevPreview.filter((_, i) => i !== index)); // Remove from preview list
  };

  const onSubmit = async (values: FormProps) => {
    console.log("values Before updating Dates", values);
    // Note: "values" object don't already have the latest Selected Date but the default date, so manually handling Dates in
    // their separate States and updating the "values" object here on Submit.
    values = { ...values, startDate: startDate, endDate: endDate };

    console.log("values After updating Dates: ", values);

    // if (startDate > endDate) {
    if (startDate.isAfter(endDate, "day")) {
      // console.log("startDate > endDate: ", startDate, startDate > endDate);      
      // console.log(`startDate.isAfter(endDate, "day"): `, startDate.isAfter(endDate, "day"));      
      // return setError("minDate");
      return toastMessage('End Date should be after or same as Start Date', 'error');
    }

    if (imageFiles.length === 0) {
      return toastMessage('Please upload advertisement images', 'error');
    }

    try {
      setLoading(true);
      // Upload new images
      const imageUploadPromises = imageFiles.map(async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        const { data: result } = await callAxios('post', 'app/user/uploadImage', formData);
        return result.url;
      });


      const imageUrls = await Promise.all(imageUploadPromises);

      const payload = {
        ...values,
        images: imageUrls,
      };

      const { data } = await callAxios('post', 'dashboard/advertisements/createAdvertisement', payload);
      console.log(data);
      setLoading(false);
      setOpen(false);
      setAdvertisements((prev) => [data.data, ...prev]);
      toastMessage('Advertisement Created Successfully', 'success');
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(error?.response?.data?.message || 'Something went wrong.', 'error');
    }
  };

  return (
    <Box sx={style} className="overflow-y-auto h-full" >
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white flex flex-col gap-6 relative p-2">
        <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2">
          <img
            src="/assets/icons/cross.svg"
            alt="close"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="publicSans text-xl font-semibold leading-7 text-center">Add Advertisement</h5>
        <FormProvider {...methods}>

          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement Name</p>
                <TextFieldElement
                  name="name"
                  fullWidth
                  size="small"
                  placeholder="Enter Advertisement Name"
                  sx={styles.root}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement Description</p>
                <TextFieldElement
                  name="description"
                  fullWidth
                  size="small"
                  placeholder="Enter Advertisement description"
                  sx={styles.root}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement Start Date</p>
                <DatePickerElement
                  // onChange={(e) => setStartDate(dayjs(e.target.value))}
                  onChange={(e) => {
                    // console.log("startDate: ", e["$d"]);
                    // setStartDate(e["$d"]);
                    setStartDate(e);
                  }}

                  control={control}
                  name="startDate"
                  sx={styles.root}
                  inputProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  // minDate={dayjs()}
                  disablePast={true}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement End Date</p>
                <DatePickerElement
                  onChange={(e) => {
                    // console.log("endDate: ", e["$d"]);
                    // setEndDate(e["$d"]);
                    setEndDate(e);
                  }}

                  control={control}
                  name="endDate"
                  sx={styles.root}
                  inputProps={{
                    size: 'small',
                    fullWidth: true,
                  }}
                  disablePast={true}
                  // End date must be atleast 1 day after the Start date
                  // minDate={watchedStartDate ? dayjs(watchedStartDate).add(1, 'day') : dayjs().add(1, 'day')}
                  // minDate={watchedStartDate && dayjs(watchedStartDate).add(1, "day")}
                  // End date must be not earlier than the Start date
                  minDate={watchedStartDate && dayjs(watchedStartDate)}
                  // rules={{ // not working
                  //   validate: (value) =>
                  //     !value || value > watchedStartDate || "End date must be after the start date",
                  // }}
                  onError={(newError) => setError(newError)}  // showing Toast instead of inline Validation Error Messages
                  // slotProps={{ // not working
                  //   textField: {
                  //     helperText: errorMessage,
                  //   },
                  // }}
                  required
                />
              </div>

              {/* DateTimePickerElement */}
              {/* <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">Advertisement End Date</p>
              <DateTimePickerElement
                name="endDate"
                sx={styles.root}
                inputProps={{
                  size: 'small',
                  fullWidth: true,
                }}
                required
                onChange={(date) => {
                  setSelectedDate(date);
                  console.log(date);
                }}
              />
            </div> */}
              <div className="w-full flex flex-col gap-1">
                <h6 className="text-tertiary publicSans text-base font-semibold">
                  Advertisement Pictures
                </h6>
                <div className="w-full border border-dotted border-[#E0E2E7] bg-[#F9F9FC] rounded-lg flex items-center justify-center py-6">
                  <div className="flex flex-col gap-4 items-center w-full">
                    <div className="rounded-lg p-3 w-full">
                      {preview.length > 0 ? (
                        <div className="grid grid-cols-4 gap-4">
                          {preview.map((img, index) => (
                            <div key={index} className="relative text-center bg-[#E8F6FF] p-3 rounded-lg shadow-md hover:shadow-lg transition-all">
                              {/* The background color here is distinct for each image */}
                              <img
                                src={img}
                                alt={`preview-${index}`}
                                className="w-16 h-16 object-cover mx-auto" // Centering the image
                              />
                              <p className="text-xs mt-2">{imageFiles[index]?.name}</p>
                              <button
                                onClick={() => deleteImage(index)}
                                style={styles.deleteButton} // Apply the styles here
                                className="hover:bg-red-600 hover:scale-110" // Add hover effect classes from tailwind or use inline styles as well
                              >
                                <CloseIcon style={{ color: '#fff', fontSize: '12px' }} /> {/* MUI Close icon */}
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <img src="/assets/icons/picture.png" alt="icon" className="w-4 h-4 mx-auto" />
                      )}
                    </div>
                    <Button
                      className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5"
                      component="label"
                      variant="contained"
                      color="secondary"
                    >
                      Add Images
                      <VisuallyHiddenInput
                        type="file"
                        required
                        accept="image/*"
                        onChange={handleImgChange}
                        multiple
                      />
                    </Button>

                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end pt-4">
                <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                  Add Advertisement
                </button>
              </div>
            </div>
          </FormContainer>

        </FormProvider>

      </div>
    </Box>
  );
}

export default AddAdvertisement;
