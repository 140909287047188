import "./App.css";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import AccountCreation from "./components/Admin/AccountCreation/AccountCreation";
import AddPet from "./components/Adoption/Pet/AddPet";
import AdoptionAvailablePets from "./components/Adoption/AvailablePets/AdoptionAvailablePets";
import AdoptionProfile from "./components/Adoption/AdoptionProfile/AdoptionProfile";
import AppointmentsTable from "./components/Vet/Appointment/AppointmentsTable";
import AvailablePets from "./components/Admin/UserManagement/AvailablePets";
import Calendar from "./components/Vet/Calendar/Calendar";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import DriversTable from "./components/Admin/TaxiManagement/DriverTable";
import MobileGroomingVanManagement from "./components/Admin/MobileGroomingVanManagement/MobileGroomingTable";
import FinancialManagement from "./components/Vet/FinancialManagement/FinancialManagement";
import ForgotPassword from "./components/Login/ForgotPassword";
import HistoryTable from "./components/Adoption/AdoptionHIstory/HistoryTable";
import Login from "./components/Login/Login";
import NewPassword from "./components/Login/NewPassword";
import Notifications from "./components/Admin/Notifications/Notifications";
import OrdersTable from "./components/Admin/Orders/OrdersTable";
import PetPassportTable from "./components/Admin/PetPassport/PetPassportTable";
import ProductcategoryTable from "./components/Admin/ProductCategory/ProductCategoryTable";
import ProductsTable from "./components/Admin/Products/Products";
import Advertisement from "./components/Admin/Advertisement/Advertisements";
import TaxiBookingTable from "./components/Admin/TaxiManagement/TaxiBookingTable";
import GroomingManagementTable from "./components/Admin/GroomingManagement/GroomingManagementTable";
import Payments from "./components/Vet/Payments/PaymentTable";
import TaxiProviderProfile from "./components/TaxiProvider/Profile/TaxiProviderProfile";
import toast, { Toaster } from "react-hot-toast";
import UserDetails from "./components/Admin/UserManagement/UserDetails";
import UsersTable from "./components/Admin/UserManagement/UsersTable";
import VerifyOtp from "./components/Login/VerifyOtp";
import VetProfile from "./components/Vet/Profile/VetProfile";
import { useAppSelector } from "./store/hooks";
import CouponsTable from "./components/Admin/Coupons/CouponsTable";
import Notification from "./components/Common/Notification/Notification"

import { isSupported } from "firebase/messaging";
import { onMessageListener } from "./utils/firebase.js";
import PrivacyPolicy from "./components/GDPR/PrivacyPolicy/PrivacyPolicy";
import SubscriptionPreferences from "./components/GDPR/SubscriptionPreferences/SubscriptionPreferences";
import ServicesTable from "./components/Admin/Services/ServicesTable";

function App() {
  // Notification related states
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [show, setShow] = useState(false);


  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state?.user);
  const { pathname } = useLocation();



  // Notification Foreground Listener
  const foregroundListener = async () => {

    const supported = await isSupported();
    // console.log('is supported: ', supported);
    // if (await isSupported()) {  
    if (supported) {
      onMessageListener()
        .then((payload) => {
          setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
          console.log(payload);
        })
        .catch((err) => console.log('failed: ', err));
    }
    else {
      console.log(`Firebase Cloud Messaging is not Supported in your Browser. "isSupported" = ${supported}`);
    }
  };
  foregroundListener();

  useEffect(() => {
    if (
      !localStorage.getItem("token") &&
      !searchParams.get("token") &&
      pathname !== "/forgot-password" &&
      pathname !== "/privacypolicy" &&
      pathname !== "/unsubscribe"
    ) {
      console.log('redirect to login route')
      navigate("/login");
    }

  }, []);


  // display Notification
  useEffect(() => {
    if (notification?.title || notification?.body) {
      toast.custom(
        (t) => (
          <Notification toastInstance={t} title={notification.title} body={notification.body} />
        ),
        // options
        {
          // icon: <Icon />,
          position: "top-right",
          duration: 5000
        }
      );
    }
  }, [notification]);

  return (
    <div className="w-full">
      {/* {isTokenFound &&
        "Notification permission enabled 👍🏻"
      }
      {!isTokenFound &&
        "Need notification permission ❗️"
      } */}
      <Toaster />
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                !localStorage.getItem('token')
                  ? '/login'
                  : user?.userType === 'ADMIN'
                    ? '/admin/user-management'
                    : '/adoption/available-pets'
              }
            />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/reset-password" element={<NewPassword />} />
        {/* Admin Routes */}
        <Route path="/admin/account-creation" element={<AccountCreation />} />
        <Route path="/admin/user-management" element={<UsersTable />} />
        <Route path="/admin/user-details" element={<UserDetails />} />
        <Route
          path="/admin/available-pets/:adoptionId"
          element={<AvailablePets />}
        />
        <Route
          path="/admin/pet-passport-database"
          element={<PetPassportTable />}
        />
        <Route
          path="/admin/financial-management"
          element={<Dashboard />}
        />
        <Route path="/admin/notifications" element={<Notifications />} />
        <Route path="/admin/services" element={<ServicesTable />} />
        <Route
          path="/admin/product-category"
          element={<ProductcategoryTable />}
        />
        <Route path="/admin/products" element={<ProductsTable />} />
        <Route path="/admin/advertisements" element={<Advertisement />} />
        <Route path="/admin/orders" element={<OrdersTable />} />
        <Route path="/admin/coupons" element={<CouponsTable />} />

        {/* Adoption Center Routes */}
        <Route
          path="/adoption/available-pets"
          element={<AdoptionAvailablePets />}
        />
        <Route
          path="/adoption/adoption-center-profile"
          element={<AdoptionProfile />}
        />
        <Route path="/adoption/add-pet" element={<AddPet />} />
        <Route path="/adoption/adoption-history" element={<HistoryTable />} />
        {/* Vet Routes */}
        <Route path="/vet/calendar" element={<Calendar />} />
        <Route path="/vet/profile" element={<VetProfile />} />
        <Route path="/vet/appointments" element={<AppointmentsTable />} />
        <Route
          path="/vet/financial-management"
          element={<FinancialManagement />}
        />
        <Route path="/vet/services" element={<ServicesTable />} />
        <Route path="/vet/products" element={<ProductsTable />} />
        <Route path="/vet/advertisements" element={<Advertisement />} />
        <Route path="/vet/orders" element={<OrdersTable />} />
        {/* Taxi Provider Routes */}
        <Route path="/taxiProvider/profile" element={<TaxiProviderProfile />} />
        <Route
          path="/vet/financial-management"
          element={<FinancialManagement />}
        />
        { /* Taxi ROUTES */}
        <Route path="/driver-management" element={<DriversTable />} />
        <Route path="/taxi-management" element={<TaxiBookingTable />} />
        <Route path="/mobile-grooming-van-man" element={<MobileGroomingVanManagement />} />
        <Route path="/mobile-grooming-management" element={<GroomingManagementTable />} />
        <Route path="/vet/payments" element={<Payments />} />

        {/* GDPR Complaince */}
        {/* PRIVACY POLICY */}
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        {/* Subscription Preferences */}
        <Route path="/unsubscribe" element={<SubscriptionPreferences />} />
      </Routes>
    </div>
  );
}

export default App;
