import { useEffect, useState } from 'react';

import CancelAppointment from '../Common/CancelAppointment';
import { CircularProgress } from '@mui/material';
import CreateAppointment from '../Common/CreateAppointment';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import PetDetails from '../Common/PetDetails';
import Resechdule from '../Common/Resechdule';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
import moment from 'moment';
// Table Imports
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useAppSelector } from '../../../store/hooks';

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const AppointmentsTable = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [appointments, setAppointments] = useState([] as any);
  const { user } = useAppSelector((state) => state.user);
  const [checked] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState({});

  const [createNewAppointment, setCreateNewAppointment] = useState(false);

  const getAllAppointments = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/appointment/getAllAppointment?page=${page}&limit=10&vet=${user?._id}`
      );
      console.log(data);

      setAppointments(data?.data);
      // setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        'Showing' +
          (data?.currentPage * 10 - 9) +
          '-' +
          data?.currentPage * 10 +
          ' of ' +
          data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  useEffect(() => {
    getAllAppointments();
  }, [page, checked]);

  return (
    <>
      {/* <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" /> */}
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              Appointment Management
            </h6>
            <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
              Appointment Management
            </p>
          </div>
          <div className="flex gap-4">
            <button
              className="border border-[#1F9CB9] bg-[#F0F7FC] rounded-lg py-1 px-3 text-[#1F9CB9] publicSans text-sm font-normal whitespace-nowrap leading-5 "
              onClick={() => {
                setCreateNewAppointment(true);
              }}
            >
              Create New Appointment
            </button>
            {/* <button className="border border-[#1F9CB9] bg-[#F0F7FC] rounded-lg py-1 px-3 text-[#1F9CB9] publicSans text-sm font-normal whitespace-nowrap leading-5 ">
              Appointment History
            </button> */}
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="inherit" size={20} />
              </div>
            ) : (
              <Table
                sx={{
                  borderCollapse: 'separate !important',
                  width: '100%',
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>Title</CustomTableHeadCell>
                    <CustomTableHeadCell>Date & Time</CustomTableHeadCell>
                    <CustomTableHeadCell>Action</CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {appointments.map((appointment: any) => {
                    return (
                      <CustomTableBodyRow key={appointment?._id}>
                        <CustomTableBodyCell>
                          <div className="w-full flex gap-2 items-center">
                            <img
                              src={
                                appointment?.pet?.profilePicture[0]
                                  ? appointment?.pet?.profilePicture[0]?.url
                                  : '/assets/images/pet.jpeg'
                              }
                              alt=""
                              className="w-8 h-8 rounded-full"
                            />
                            <div className="flex flex-col gap-1">
                              <p className="text-[#093147] publicSans text-sm  font-medium leading-5">
                                {appointment?.pet?.name}
                              </p>
                              <p className="publicSans text-xs font-normal leading-4 tracking-tight">
                                {appointment?.pet?.breed}
                              </p>
                            </div>
                          </div>
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(appointment?.appointmentTimesamp).format(
                            'DD-MM-YYYY'
                          ) +
                            ` (${appointment.startTime} - ${appointment.endTime}) `}
                        </CustomTableBodyCell>

                        <CustomTableBodyCell>
                          <div className="w-full flex gap-3 items-center">
                            {/* <button className="text-[#1F9CB9] publicSans text-sm font-medium leading-5">
                            Pet Passport
                          </button> */}
                            <button
                              className="border border-[#11C661] rounded-lg publicSans text-sm py-2 px-3 font-medium leading-5 text-[#11C661]  "
                              onClick={() => {
                                setSelected(appointment);
                                setRescheduleOpen(true);
                              }}
                            >
                              Reschedule
                            </button>
                            <button
                              onClick={() => {
                                setSelected(appointment);
                                setOpenDetails(true);
                              }}
                              className="text-white publicSans text-sm font-normal leading-5 bg-[#1F9CB9] py-2 px-3 rounded-lg "
                            >
                              Details
                            </button>
                            <button
                              onClick={() => {
                                setSelected(appointment);
                                setOpenCancel(true);
                              }}
                              className="text-white publicSans text-sm font-normal leading-5 bg-[#EF8214] py-2 px-3 rounded-lg "
                            >
                              Cancel
                            </button>
                          </div>
                        </CustomTableBodyCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>
      <Modal
        open={rescheduleOpen}
        onClose={() => {
          setRescheduleOpen(false);
          getAllAppointments();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Resechdule
          setOpen={(value) => {
            setRescheduleOpen(value);
            if (!value) {
              getAllAppointments();
            }
          }}
          serviceId={
            (selected as any).services ? (selected as any).services[0]._id : ''
          }
          bookingId={(selected as any)._id}
        />
      </Modal>
      <Modal
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CancelAppointment
          setOpen={setOpenCancel}
          selected={selected}
          getAllAppointments={getAllAppointments}
        />
      </Modal>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PetDetails
          setOpen={setOpenDetails}
          existingData={selected}
          setOpenCancel={setOpenCancel}
        />
      </Modal>
      <Modal
        open={createNewAppointment}
        onClose={() => setCreateNewAppointment(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateAppointment
          setOpen={(value) => {
            setCreateNewAppointment(value);
            getAllAppointments();
          }}
        />
      </Modal>
    </>
  );
};

export default AppointmentsTable;
