import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import Loading from "react-fullscreen-loading";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
import PetDetails from "../../Adoption/AvailablePets/PetDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { callAxios } from "../../../utils/axios";
// Table Imports
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";
import { useNavigate } from "react-router";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: "Public Sans",
  fontSize: "14px",
  lineHeight: "20px",
  borderBottom: "none",
  color: "#1D1F2C",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontFamily: "Public Sans",
  fontSize: "14px",
  fontWeight: 500, // Customize cell text styling
  lineHeight: "20px",
  borderBottom: "none",
  color: "#667085",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff", // Customize table head row background color
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const PetPassportTable = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [passports, setPassports] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState("");
  const [checked, setChecked] = useState(false);
  const [pet, setPet] = useState({});

  const [open, setOpen] = useState(false);

  const getAllPassports = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/pet/getPet?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);
      setPassports(data.data);
      setTotalPages(data.totalPage);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, "error");
    }
  };

  useEffect(() => {
    getAllPassports();
  }, [page, searchText]);
  return (
    <>
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Pet Passport Database
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Pet Passport Database
          </p>
        </div>
        <div className="w-full bg-white rounded-lg shadow p-4 px-8 flex justify-between gap-2">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
          <div className="w-full">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (<Table
              sx={{
                borderCollapse: 'separate !important',
                width: '100%',
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Pet Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Micro Chip Number</CustomTableHeadCell>
                  <CustomTableHeadCell>Owner Phone No</CustomTableHeadCell>
                  <CustomTableHeadCell>Pet Type</CustomTableHeadCell>
                  <CustomTableHeadCell>Action</CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {passports.map((user) => {
                  return (
                    <CustomTableBodyRow key={user._id}>
                      <CustomTableBodyCell>{user.name}</CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user.microchipNumber}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user?.user?.countryCode
                          ? `(${user?.user?.countryCode}) ${user?.user?.phone}`
                          : user?.user?.phone}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{user.breed}</CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <button
                          onClick={() => {
                            setPet(user);
                            setOpen(true);
                          }}
                          className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded "
                        >
                          View Pet Passport
                        </button>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>)}
          </div>
          <div className="w-full flex justify-between items-center p-4">
            <p className="publicSans text-sm font-medium text-[#667085] leading-5">
              {dataRange}
            </p>
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={(e, value) => setPage(value)}
            />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PetDetails
          setOpen={setOpen}
          existingData={pet}
          checked={checked}
          setChecked={setChecked}
        />
      </Modal>
    </>
  );
};

export default PetPassportTable;
