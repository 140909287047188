import {
  AutocompleteElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { CropperModal } from '../../Common/ImageCropper';
import InputBase from '@mui/material/InputBase';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';
import { useAppSelector } from '../../../store/hooks';
import { useSearchParams } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

type FormProps = {
  petCategory: string;
  name: string;
  serviceType: string;
  serviceTypeCheck: string;
  description: string;
  price: string;
  priceMedium: string;
  priceLarge: string;
  priceExtraLarge: string;
  time: number;
  timeMedium: number;
  timeLarge: number;
  timeExtraLarge: number;
  topCategories: string[];
  primaryService: string[];
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AddService = ({ setOpen, setServices, checked, setChecked }: any) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const [isChecked, setIsChecked] = useState(false)
  const [petCategoryOptions, setPetCategoryOptions] = useState([]);
  const [primaryServices, setPrimaryServices] = useState([] as any);

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const checkBoxHandler = (event) => {
    setIsChecked(event.target.checked);
  };
  const getServiceType = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        'dashboard/services/getServiceType?page=1&limit=100'
      );

      console.log(data);

      // setServiceTypeOptions(
      //   data?.data?.map((item: any) => {
      //     return {
      //       label: item.name,
      //       id: item._id,
      //     };
      //   })
      // );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const getPrimaryServices = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        // "dashboard/services/getAllServices?page=1&limit=100&serviceTypeCheck=PRIMARY"
        `dashboard/services/getAllServices?page=1&limit=100&serviceTypeCheck=PRIMARY&vet=${user._id}`
      );

      console.log(data);

      setPrimaryServices(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  const getPetCategory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        'app/pet/getPetCategory?page=1&limit=100'
      );

      console.log(data);

      setPetCategoryOptions(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getServiceType();
    getPetCategory();
    getPrimaryServices();
  }, []);
  const defaultValues: FormProps = {
    petCategory: '',
    name: '',
    serviceType: '',
    serviceTypeCheck: '',
    description: '',
    price: '',
    priceMedium: '',
    priceLarge: '',
    priceExtraLarge: '',
    time: 0.5,
    timeMedium: 0.5,
    timeLarge: 0.5,
    timeExtraLarge: 0.5,
    topCategories: [],
    primaryService: [],
  };
  const [inputField, setInputField] = useState(defaultValues);
  const onInputChange = (e: any) => {
    console.log(e.target.value);

    const { name, value } = e.target;
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onInputSelectChange = (name, value: any) => {
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = async (values: FormProps) => {

    // console.log("values.primaryService: ", values.primaryService);

    // console.log("values.primaryService.map((item: any) => item?.id): ", values.primaryService.map((item: any) => item?.id));

    // return;

    if (!image) {
      return toastMessage('Please upload a profile picture', 'error');
    }
    if (values.time === 0) {
      return toastMessage('Please select time', 'error');
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', image);
      const { data: result } = await callAxios(
        'post',
        'app/user/uploadImage',
        formData
      );
      console.log(result);

      const payload = {
        ...values,
        images: [result?.url],
        vet: user._id,
        time: values.time * 60,
        timeMedium: values.timeMedium * 60,
        timeLarge: values.timeLarge * 60,
        timeExtraLarge: values.timeExtraLarge * 60,
        topCategories: values.topCategories.map((item: any) => item?.id),
        primaryService: values.primaryService.map((item: any) => item?.id),
        isMobileGrooming: isChecked
      };
      // console.log(payload);
      // debugger;
      // return;

      const { data } = await callAxios(
        'post',
        'dashboard/services/addService',
        payload
      );
      console.log(data);

      // setLoading(false);
      // return;
      
      // setChecked(!checked);
      setInputField(defaultValues);
      setSrc(null);
      setPreview(null);
      setImage('');

      setLoading(false);

      setServices((prev) => [data.data, ...prev]);

      setOpen(false);

      toastMessage('Service Created Successfully', 'success');
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  return (
    <>
      <Box sx={style} className="overflow-y-auto h-full" >
        <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
        <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
          <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
            <img
              src="/assets/icons/cross.svg"
              alt="close"
              className="cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>
          <h5 className="publicSans text-xl font-semibold leading-7 text-center  ">
            Add Service
          </h5>
          <FormContainer
            // defaultValues={defaultValues} onSuccess={onSubmit}
            defaultValues={inputField}
            onSuccess={onSubmit}
            values={inputField}
            resetOptions={{
              keepValues: false,
              keepIsSubmitted: false,
              keepTouched: false,
              keepDirty: false,
              keepIsValid: false,
              keepSubmitCount: false,
            }}
            shouldFocusError={true}
          >
            <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
              <h6 className="text-tertiary publicSans text-base font-semibold ">
                Add New Service
              </h6>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Pet Types
                  </p>
                  <SelectElement
                    name="petCategory"
                    value={inputField?.petCategory}
                    onChange={(value) => onInputSelectChange('petCategory', value)}
                    fullWidth
                    size="small"
                    placeholder="Center Name"
                    sx={styles.root}
                    options={petCategoryOptions}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Name
                  </p>
                  <TextFieldElement
                    name="name"
                    value={inputField?.name}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    placeholder="Enter Service Name"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  {/* <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Type
              </p>
              <SelectElement
                name="serviceType"
                value={inputField?.serviceType}
                onChange={(value) => onInputSelectChange("serviceType", value)}
                fullWidth
                size="small"
                placeholder="Email"
                sx={styles.root}
                required
                options={serviceTypeOptions}
              /> */}
                </div>
              </div>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Types
                  </p>
                  <RadioButtonGroup
                    onChange={(value) => {
                      setInputField((prev) => ({
                        ...prev,
                        serviceTypeCheck: value,
                      }));
                    }}
                    name="serviceTypeCheck"
                    options={[
                      {
                        id: 'PRIMARY',
                        label: 'Primary',
                      },
                      {
                        id: 'SECONDARY',
                        label: 'Secondary',
                      },
                    ]}
                    row
                    required
                  />
                </div>
                {inputField?.serviceTypeCheck == 'SECONDARY' && (
                  <div className="w-full flex flex-col gap-1">
                    <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                      Choose Primary Service
                    </p>

                    {/* to do: the "primaryServices" array's label should have unique values because it uses them
                                as "key" attribute while creating the option elements by looping through.
                                make a check on backend to make the "Service Names" unique otherwise this component
                                is slowing the page.
                    */}
                    <AutocompleteElement
                      name="primaryService"
                      required
                      multiple
                      autocompleteProps={{
                        sx: styles.root,
                        size: "small",
                      }}
                      options={primaryServices}
                    />
                    {/* <SelectElement
                      name="primaryService"
                      value={inputField?.primaryService}
                      onChange={(value) =>
                        onInputSelectChange('primaryService', value)
                      }
                      fullWidth
                      size="small"
                      placeholder="Email"
                      sx={styles.root}
                      required
                      options={primaryServices}
                    /> */}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 w-full ">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Service Description
                </p>
                <TextFieldElement
                  name="description"
                  value={inputField?.description}
                  onChange={onInputChange}
                  fullWidth
                  size="small"
                  placeholder="Enter Service Description"
                  sx={styles.root}
                  required
                  multiline
                  rows={4}
                />
              </div>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Categories
                  </p>
                  <AutocompleteElement
                    name="topCategories"
                    required
                    multiple
                    autocompleteProps={{
                      sx: styles.root,
                      size: 'small',
                      // onChange: (e, value) => {
                      //   setInputField((prev) => ({
                      //     ...prev,
                      //     topCategories: value,
                      //   }));
                      // },
                    }}
                    // options={[
                    //   { label: 'Mobile Pet Grooming', id: 'Mobile Pet Grooming' },
                    //   { label: 'Instore Pet Grooming', id: 'Instore Pet Grooming' },
                    //   { label: 'Pet Boarding', id: 'Pet Boarding' },
                    //   { label: 'Pet Daycare', id: 'Pet Daycare' },
                    //   { label: 'Pet Sitting', id: 'Pet Sitting' },
                    //   { label: 'Pet Walking', id: 'Pet Walking' },
                    //   { label: 'Pet Products', id: 'Pet Products' },
                    //   { label: 'Pet Food', id: 'Pet Food' },
                    //   { label: 'Pet Accessories', id: 'Pet Accessories' },
                    //   { label: 'Pet Taxi', id: 'Pet Taxi' },
                    //   { label: 'Pet Training', id: 'Pet Training' }
                    // ]}
                    options={[
                      { label: 'Pet Grooming', id: 'Pet Grooming' },
                      { label: 'Mobile Pet Grooming', id: 'Mobile Pet Grooming' },
                      // { label: 'Instore Grooming', id: 'Instore Grooming' },
                      { label: 'Boarding', id: 'Boarding' },
                      { label: 'Daycare', id: 'Daycare' },
                      { label: 'Training', id: 'Training' },
                      { label: 'Pet Products', id: 'Pet Products' },
                      { label: 'Pet Taxi', id: 'Pet Taxi' },
                      // { label: 'Mobile Grooming', id: 'Mobile Grooming' },
                    ]}
                  />
                </div>

                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Small
                  </p>
                  <SelectElement
                    name="time"
                    fullWidth
                    size="small"
                    placeholder="Select Time"
                    sx={styles.root}
                    required
                    options={[
                      { label: '0.5 Hour', id: 0.5 },
                      { label: '1 Hour', id: 1 },
                      { label: '1.5 Hour', id: 1.5 },
                      { label: '2 Hour', id: 2 },
                      { label: '2.5 Hour', id: 2.5 },
                      { label: '3 Hour', id: 3 },
                      { label: '3.5 Hour', id: 3.5 },
                      { label: '4 Hour', id: 4 },
                      { label: '4.5 Hour', id: 4.5 },
                      { label: '5 Hour', id: 5 },
                      { label: '5.5 Hour', id: 5.5 },
                      { label: '6 Hour', id: 6 },
                    ]}
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Medium
                  </p>
                  <SelectElement
                    name="timeMedium"
                    fullWidth
                    size="small"
                    placeholder="Select Time"
                    sx={styles.root}
                    required
                    options={[
                      { label: '0.5 Hour', id: 0.5 },
                      { label: '1 Hour', id: 1 },
                      { label: '1.5 Hour', id: 1.5 },
                      { label: '2 Hour', id: 2 },
                      { label: '2.5 Hour', id: 2.5 },
                      { label: '3 Hour', id: 3 },
                      { label: '3.5 Hour', id: 3.5 },
                      { label: '4 Hour', id: 4 },
                      { label: '4.5 Hour', id: 4.5 },
                      { label: '5 Hour', id: 5 },
                      { label: '5.5 Hour', id: 5.5 },
                      { label: '6 Hour', id: 6 },
                    ]}
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Large
                  </p>
                  <SelectElement
                    name="timeLarge"
                    fullWidth
                    size="small"
                    placeholder="Select Time"
                    sx={styles.root}
                    required
                    options={[
                      { label: '0.5 Hour', id: 0.5 },
                      { label: '1 Hour', id: 1 },
                      { label: '1.5 Hour', id: 1.5 },
                      { label: '2 Hour', id: 2 },
                      { label: '2.5 Hour', id: 2.5 },
                      { label: '3 Hour', id: 3 },
                      { label: '3.5 Hour', id: 3.5 },
                      { label: '4 Hour', id: 4 },
                      { label: '4.5 Hour', id: 4.5 },
                      { label: '5 Hour', id: 5 },
                      { label: '5.5 Hour', id: 5.5 },
                      { label: '6 Hour', id: 6 },
                    ]}
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Extra Large
                  </p>
                  <SelectElement
                    name="timeExtraLarge"
                    fullWidth
                    size="small"
                    placeholder="Select Time"
                    sx={styles.root}
                    required
                    options={[
                      { label: '0.5 Hour', id: 0.5 },
                      { label: '1 Hour', id: 1 },
                      { label: '1.5 Hour', id: 1.5 },
                      { label: '2 Hour', id: 2 },
                      { label: '2.5 Hour', id: 2.5 },
                      { label: '3 Hour', id: 3 },
                      { label: '3.5 Hour', id: 3.5 },
                      { label: '4 Hour', id: 4 },
                      { label: '4.5 Hour', id: 4.5 },
                      { label: '5 Hour', id: 5 },
                      { label: '5.5 Hour', id: 5.5 },
                      { label: '6 Hour', id: 6 },
                    ]}
                  />
                </div>

                {/* <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Small (In Minutes)
                  </p>
                  <TextFieldElement
                    name="time"
                    value={inputField?.time}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Medium (In Minutes)
                  </p>
                  <TextFieldElement
                    name="timeMedium"
                    value={inputField?.timeMedium}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Large (In Minutes)
                  </p>
                  <TextFieldElement
                    name="timeLarge"
                    value={inputField?.timeLarge}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Time for Extra Large (In Minutes)
                  </p>
                  <TextFieldElement
                    name="timeExtraLarge"
                    value={inputField?.timeExtraLarge}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    sx={styles.root}
                    required
                  />
                </div> */}

                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Upload Service Image
                  </p>
                  {preview ? (
                    <>
                      <img src={preview} className='w-16 h-16'></img>
                      <Button
                        className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                        component="label"
                        variant="contained"
                        color="secondary"
                      >
                        Add Image
                        <VisuallyHiddenInput
                          type="file"
                          required
                          accept="image/*"
                          onChange={handleImgChange}
                        />
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                      component="label"
                      variant="contained"
                      color="secondary"
                    >
                      Add Image
                      <VisuallyHiddenInput
                        type="file"
                        required
                        accept="image/*"
                        onChange={handleImgChange}
                      />
                    </Button>
                  )}
                </div>
              </div>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Small
                  </p>
                  <TextFieldElement
                    name="price"
                    value={inputField?.price}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price for Small"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Medium
                  </p>
                  <TextFieldElement
                    name="priceMedium"
                    value={inputField?.priceMedium}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Large
                  </p>
                  <TextFieldElement
                    name="priceLarge"
                    value={inputField?.priceLarge}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full grid grid-cols-3 gap-4">
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service Price for Extra Large
                  </p>
                  <TextFieldElement
                    name="priceExtraLarge"
                    value={inputField?.priceExtraLarge}
                    onChange={onInputChange}
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Enter Service Price"
                    sx={styles.root}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex justify-end pt-4">
                <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
                  Create Service
                </button>
              </div>
            </div>
          </FormContainer>
        </div>
        <CropperModal
          modalOpen={modalOpen}
          src={src}
          setImage={setImage}
          setPreview={setPreview}
          setModalOpen={setModalOpen}
        />
      </Box>

    </>
  );
};
export default AddService;
